@font-face {
    font-family: 'Lato';
    src: local('Lato Regular'), local('Lato-Regular'), url(../fonts/Lato-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato Regular'), local('Lato-Regular'), url(../fonts/Lato-Regular.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato Regular'), local('Lato-Regular'), url(../fonts/Lato-Regular.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

html {
    height: 100vh;
}

body {
    margin: 0;
}

.diagramContainer {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
}

.base-canvas {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
}

.alignedContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 60px);
}

.frame {
    padding: 10px;
    margin: 10px;
    font-size: small;
    text-align: center;
    border-radius: 5px;
}

.darkFrame {
    background-color: #1B1B25 !important;
}

.darkFrame * {
    color: #FFF;
}

.greyFrame {
    background-color: #D1D1D1;
}

.k-sparkline {
    width: 100% !important;
}

.k-sparkline svg > g path {
    fill: rgba(255, 255, 255, 0) !important;
}

.k-sparkline svg > g > g path {
    stroke: rgba(0, 0, 0, 1) !important;
    stroke-width: 2px !important;
}

.helice {
    transform-origin: 28% 28%;
    transform: rotate(0deg);
    animation: 1s linear infinite rotator;
}

.agitator-on {
    transform-origin: 47.5%;
    animation: 1s linear infinite agitator-rotator;
}

.diagramText {
    color: #494953;
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 6px;
    text-anchor: middle;
}

.LD__Checkbox label {
    margin-bottom: 0;
}

.adapt {
    min-height: 220px !important;
}

.even-popup-content {
    padding: 10px;
    background-color: #e6e6e6;
}

.sequenceList .LD__Card {
    cursor: pointer;
}

.sequenceList {
    overflow-y: auto;
    background-color: #F3F3F7;
    border-radius: 8px;
    padding: 15px 0 15px 0;
    height: calc(100vh - 500px);
}

.scrollbar
{
    margin-left: 30px;
    background: #F5F5F5;
    overflow: auto;
    margin-bottom: 25px;
}
.scrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 0px 5px 5px 0px;
}
.scrollbar::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}
.scrollbar::-webkit-scrollbar-thumb
{
    background-color: rgb(190, 190, 197);
    border-radius: 0px 5px 5px 0px;
}

@keyframes rotator {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes agitator-rotator {
    from {
        transform: rotateY(0deg) translate(-2px, 49px);
    }
    to {
        transform: rotateY(360deg) translate(-2px, 49px);
    }
}

.k-tabstrip > .k-content.k-state-active > div {
    height: 100%;
    /*background-color: red;*/
}

.adapt {
    min-height: 180px !important;
    width: 65%;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.adapt-left {
    min-height: 180px !important;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 10px;
    width: 15%;
}

.adapt-right {
    min-height: 180px !important;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 0px;
    width: 20%;
}

.ScrollBar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 5px 5px 5px 5px;
}
.ScrollBar::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}
.ScrollBar::-webkit-scrollbar-thumb
{
    background-color: rgb(190, 190, 197);
    border-radius: 5px 5px 5px 5px;
}

.ScrollBar {
    overflow-y: auto;
    overflow-x: hidden;
}

.VirtualSensorNodeLabel {
    margin-bottom: 10px;
    /*border: black;*/
    /*border-style: solid;*/
    /*border-radius: 10px;*/
    /*border-width: medium;*/
    /*background-color: rgb(248, 248, 252);*/
}

.k-animation-container{
    z-index: 10003
}

.noHover:hover {
    cursor: default;
    box-shadow: none !important;
}

.gridWithFilterIcon span.k-icon.k-i-more-vertical:before {
    content: "\e129";
}
