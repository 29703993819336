.title {
    padding: 80px;
    margin: 20px;
}

.user-details {
    display: inline-block;
    font: 400 13.3333px Arial;
    margin: 10px;
}

.user-name-text {
    height: 15px;
    width: 335px;
    color: #FFFFFF !important;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.23px;
    line-height: 15px;
    float: right;
    position: relative;
    top: 10px;
    left: 5px;
    text-align: left !important;
}

#userProfile {
    background-image: url(../../images/users.svg);
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: round;
    height: 30px;
    width: 30px;
    margin: 0 auto;
    margin-top: 10px;
    fill: #FFFFFF !important;
}

#userProfile:hover,
#userProfile.active {
    background-image: url(../../images/users.svg);
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: round;
}

#userDetails {
    background-image: url(../../images/users.svg);
    display: inline-block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: round;
    height: 30px;
    width: 30px;
    margin: 0 auto;
}

#topmenu-alarm {
    background-image: url(../../images/menu-alarm.svg);
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: round;
    height: 30px;
    width: 30px;
    margin: 0 auto;
}

.badge {
    border-radius: 50%;
    position: absolute;
    top: 11px;
    right: 16px;
    font-size: 12px;
    height: 20px;
    width: 20px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    line-height: 15px;
    text-align: center;
    padding: 2px 2px 2px 2px;
}

.notification-msg {
    font-size: 12px;
    border-bottom: 1px solid lightgray;
    padding: 10px;
}

.iconcompName li {
    text-decoration: none;
    display: inline-flex;
}

.logo {
    width: 41.88px;
    background-color: #FFFFFF;
}

.company-name {
    height: 24.5px;
    width: 284.77px;
    color: #B9ADD3;
    font-family: Lato;
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    margin-left: 77px;
    margin-top: 20px;
}

#mercklogo {
    content: url('../../images/Logo.png');
    background-color: #503291;
    position: relative;
    top: 8px;
}

#icontitle {
    height: 15px;
    width: 60px;
    font-family: Lato;
    font-size: 10px;
    font-weight: 900;
    letter-spacing: 0.17px;
    line-height: 15px;
    text-align: center;
    margin-left: 10px;
    text-transform: capitalize;
    color: #767680;
    padding-bottom: 35px;
}

#icontitle:hover,
#icontitle.active {
    color: #2DBECD;
    padding-top: 0px !important;
}

.time {
    height: 24.5px;
    width: 180px;
    color: #FFFFFF;
    font-family: Lato;
    font-size: 14px;
    font-weight: 900;
    line-height: 24px;
    text-align: right;
    padding-right: 20px;
    margin-top: 20px;
}

.vl {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    height: 60px;
    margin-right: 15px;
    margin-left: 15px;
}

.userIcon li {
    padding-right: 0px;
}

.alarmIcon {
    height: 60px;
    width: 60px;
    margin: auto;
}

.alarmIcon li {
    padding-left: 20px;
    padding-right: 20px;
}

.sub-text-right {
    height: 15px;
    width: 77px;
    color: rgba(255, 255, 255, 0.8);
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 15px;
}

.userIcon .dropdown-menu {
    transform: translate3d(10px, 12px, 0px);
    right: unset;
}

.carddrop {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    right: 14px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-right: 1px solid #cad5e0;
    border-bottom: 1px solid #cad5e0;
    -ms-transform: rotate(225);
    transform: rotate(225);
    -webkit-transform: rotate(225deg);
}

.user-dropdown-topmenu:hover {
    -webkit-box-shadow: 0 0 3px 3px #d9d9d9 !important;
    box-shadow: 0 0 3px 3px #d9d9d9 !important;
    cursor: pointer;
}

.user-dropdown-topmenu {
    right: 125px !important;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.plcError {
    width:40px;
    animation: blink normal 3s infinite ease-in-out;
    position: relative;
    top: -3px;
}