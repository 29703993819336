.wrapper {
    color: #494953;
}

.inner-wrapper {
    padding: 15px;
    background-color: whitesmoke;
    border-radius: 15px;
}

.k-tabstrip > .k-content > .k-animation-container {
    width: 100%;
    min-height: 340px;
}