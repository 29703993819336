/** AlarmNotification component starts **/
#orch-notification_wrapper {
    position: relative;
    width: 55px;
    height: 44px;
}

.orch-notification_preview_container {
    position: relative;
    float: left;
    width: 260px;
    height: 44px;
    z-index: 1;
}

.orch-notification_icon_container {
    position: relative;
    width: 35px;
    height: 30px;
    margin-top: 17px;
    margin-left: 10px;
    margin-right: 10px;
}

.blink_me {
    animation: blinker 1s linear 1s 4;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/** AlarmNotification component ends **/
/** NotificationIcon component starts **/
#orch-notification_icon {
    border-radius: 50%;
}

#orch-notification_icon:hover {
    cursor: pointer;
}

.orch-notification_image {
    position: relative;
    float: left;
    background-image: url('../../images/Alarmicon_onhover.svg');
    height: 30px;
    width: 30px;
    background-size: 100%;
}

.orch-notification_count {
    position: absolute;
    float: right;
    background-color: rgb(218, 4, 4);
    border-radius: 50%;
    width: max-content;
    height: 20px;
    padding: 0 6px;
    font-size: 10pt;
    top: -4px;
    right: -12px;
}

/** NotificationIcon component ends **/
/** NotificationContent component starts **/
#orch-notification_content {
    width: 100%;
    height: 44px;
    font-size: 8pt;
    padding: 0 0 0 10px;
    border-radius: 4px;
}

#orch-category_border {
    width: 10px;
    height: 63px;
    float: left;
}

#orch-content_wrapper {
    width: 264px;
    height: 52px;
    float: left;
    padding: 10px 0 10px 8px;
}

#orch-notification_content .title {
    font-weight: bold;
    max-width: 98%;
}

#orch-notification_content .title {
    padding-top: 6px;
}

#orch-notification_content .desc {
    color: rgb(99, 98, 98);
    max-width: 98%;
}

.truncateText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#orch-notification_content .time {
    font-weight: bold;
    font-size: 7pt;
    width: 50%;
    float: left;
}

.elapsed_time {
    font-size: 7pt;
    width: 50%;
    float: left;
}

#orch-notification_content .time, .elapsed_time {
    padding-top: 4px;
}

.elapsed_label {
    width: 55%;
    float: left;
}

.elapsed_value {
    width: 45%;
    float: left;
}

.bkground_red {
    background-color: rgb(218, 4, 4);
}

.bkground_orange {
    background-color: rgb(252, 171, 22)
}

.bkground_gery {
    background-color: #e8edf1;
    opacity: 0.5;
}

.bkground_white {
    background-color: #fff
}

.bkground_blue {
    background-color: #4E92E3;
}

.orch-notification_border {
    border-bottom: 1px solid #afb1b3
}

/** NotificationContent component ends **/
/** NotificationList component starts **/
#orch-notification .dropdown {
    height: 44px;
    width: 44px;
}

#orch-notification .dropdown #orch-notification_content {
    height: 64px;
    padding: 0;
}

#orch-notification .dropdown-menu {
    top: 37px !important;
    left: 246px !important;
    padding: 0px !important;
    border-top: 1px solid #afb1b3 !important;
    border-right: 1px solid #afb1b3 !important;
    border-bottom: none !important;
    border-left: none !important;
    -webkit-box-shadow: rgba(184, 184, 184, 0.4) -2px 2px 8px 4px;
    box-shadow: rgba(184, 184, 184, 0.4) -2px 2px 8px 4px;
    max-height: 321px;
    width: 294px;
    overflow-y: auto;
}

#orch-notification .dropdown-menu.expand_list {
    max-height: 513px;
    width: 300px;
    overflow-y: auto;
}

#orch-notification .dropdown-menu.collapse_list {
    max-height: 355px;
    width: 294px;
    overflow: hidden;
}

#orch-notification .dropdown-item {
    padding: 0 1px !important;
}

#orch-notification .dropdown #notification_content {
    border-radius: 0 !important;
}

.dropdown #orch-notification_content .title {
    padding-top: 0px !important;
}

.dropdown #orch-notification_content .desc, .dropdown #orch-notification_content .time, .dropdown #notification_content .elapsed_time {
    padding-top: 2px !important;
}

#orch-notification .dropdown-item.view_all {
    padding: 6px 12px !important;
    font-size: 8pt;
    color: #4E92E3;
    font-weight: bold;
    border-bottom: 1px solid #afb1b3 !important;
    border-left: 1px solid #afb1b3 !important;
    border-radius: 0 3px 0 3px;
}

/** NotificationList component ends **/
.orch-notification-header-title {
    color: #FFFFFF;
    font-family: Lato;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.23px;
    line-height: 15px;
    margin-top: 10px;
    margin-left: 20px;
    height: 15px;
}

.orch-notification-header-description {
    color: #FFFFFF;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 15px;
    margin-left: 20px;
    height: 15px;
}

.orch-notification-close {
    content: url('../../images/menu-alarm.svg');
    float: right;
    height: 10px;
    width: 10px;
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.orch-notification-header-navbar {
    position: absolute;
    right: 380px;
    width: 542px;
    height: 60px;
    top: 0px;
}

.orch-notification-title {
    height: 15px;
    width: 91px;
    color: #4A4A4A;
    font-family: Verdana;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 13px;
    position: relative;
    right: 13px;
    margin-top: 10px;
}

.orch-notification-description {
    height: 15px;
    width: 175px;
    color: #000000;
    font-family: Verdana;
    font-size: 12px;
    line-height: 15px;
    position: relative;
    right: 13px;
    margin-top: 7px;
}

.orch-notification-time-block {
    position: relative;
    right: 13px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.orch-notification-view {
    box-sizing: border-box;
    height: 33px;
    width: 60.89px;
    border: 1px solid #ADB5BD;
    border-radius: 3px;
    background-color: #FFFFFF;
}

.orch-view-text {
    height: 23px;
    width: 60px;
    color: #4A4A4A;
    font-family: Verdana;
    font-size: 11px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
}

.orch-notification-details-left {
    display: inline-block;
}

.orch-notification-view-right {
    position: relative;
    bottom: 10px;
    display: none;
}

.orch-notification-time {
    color: #000000;
    font-family: Verdana;
    font-size: 10px;
    line-height: 12px;
    font-weight: bold;
    display: inline-block;
}

.orch-notification-duration {
    display: inline-block;
    color: #000000;
    font-family: Verdana;
    font-size: 10px;
    line-height: 12px;
    margin-left: 10px;
}

.orch-notification-duration-label {
    display: inline-block;
    color: #000000;
    font-family: Verdana;
    font-size: 10px;
    line-height: 12px;
}

.orch-notification-duration-timer {
    display: inline-block;
}

.alarm-dropdown {
    width: 375px !important;
    top: -17px !important;
    left: 160px !important;
}

.alarm-truncateText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 265px;
}

.alarm-header-truncateText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 365px;
}

.content_bkground_blue {
    border-left: 12px solid #2DBECD !important;
    border-bottom: 1px solid #ADB5BD !important;
}

.content_bkground_red {
    border-left: 12px solid #ED1A3B !important;
    border-bottom: 1px solid #ADB5BD !important;
}

.content_bkground_orange {
    border-left: 12px solid #F5A623 !important;
    border-bottom: 1px solid #ADB5BD !important;
}

.content_bkground_gery {
    border-left: 12px solid #ADB5BD !important;
    border-bottom: 1px solid #ADB5BD !important;
}

.content_bkground_white {
    border-left: 12px solid #fff !important;
    border-bottom: 1px solid #ADB5BD !important;
}

/* Header Notification */
.header_bkground_blue {
    background-color: #2DBECD !important;
}

.header_bkground_red {
    background-color: #ED1A3B !important;
}

.header_bkground_orange {
    background-color: #F5A623 !important;
}

.header_bkground_gery {
    background-color: #ADB5BD !important;
}

.header_bkground_white {
    background-color: #fff !important;
}

.alarm-dropdown:hover {
    -webkit-box-shadow: 0 0 3px 3px #d9d9d9 !important;
    box-shadow: 0 0 3px 3px #d9d9d9 !important;
    cursor: pointer;
}

.alarm-view-all-block {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    line-height: 15px;
    color: #2DBECD;
    height: 15px;
    margin-top: 13.5px;
    margin-bottom: 11px;
    margin-left: 16px;
}

.alarm-carddrop {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 44%;
    width: 10px;
    height: 10px;
    background: #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -ms-transform: rotate(225);
    transform: rotate(225);
    -webkit-transform: rotate(225deg);
}

.alarm-clear-all {
    height: 13px;
    color: #767680;
    font-family: Verdana;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    text-align: right;
    float: right;
    margin-right: 21px;
}

.opacity-clear-all {
    color: rgba(173, 181, 189, 0.28) !important;
    cursor: not-allowed;
}