/* Hide scrollbar for Chrome, Safari and Opera */


.SummarySidebar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 5px 5px 5px 5px;
}
.SummarySidebar::-webkit-scrollbar
{
    width: 10px;
    background-color: #F5F5F5;
}
.SummarySidebar::-webkit-scrollbar-thumb
{
    background-color: rgb(190, 190, 197);
    border-radius: 5px 5px 5px 5px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.SummarySidebar {
    background-color: #f7f3f4;
    width: 170px;
    height: 100%;
    min-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}